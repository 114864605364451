import {useState, useEffect} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import FileUpload from './components/FileUpload';
import Report from './pages/Report';
import Iterations from './pages/Iterations';
import SummaryReport from './pages/SummaryReport';
import Signup from './components/Signup';
import Login from './components/Login';
import NavbarContext from './components/NavbarContext';
import ProtectedRoute from './components/ProtectedRoute';
import PdfGenerator from './components/PdfGenerator';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import FormDataSection from './components/FormDataSection';

const App = () => {
  const [isLoginPage,setIsLoginPage] = useState(true);
  const [categoryName,setCategoryName] = useState('');
  const [category,setCategory] = useState('');

  return (
    <NavbarContext.Provider value={{
        isLoginPage,
        setIsLoginPage,
        categoryName,
        setCategoryName,
        category,
        setCategory
        
      }}>
      <Routes>
        <Route element={<ProtectedRoute />} >
          <Route path="/home" element={<Home />}  />
          <Route path="FileUpload" element={<FileUpload />} />
          <Route path="/Report/:iterationId/:companyId" element={<Report />} />
          <Route path="/MobileReports/:iterationId/:companyId" element={<FormDataSection/>}/>
          <Route path="/Iterations" element={<Iterations />} />
          <Route path="/SummaryReport/:iterationId/:companyId/:encrypted_email?" element={<SummaryReport />} />
        </Route>
        <Route path="/Signup" element={<Signup />} />
        <Route path="/" element={<Login />} exact />
        <Route path="/Pdf" element={<PdfGenerator />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/ResetPassword/:access_token" element={<ResetPassword />} />
      </Routes>
      </NavbarContext.Provider>
  );
};

export default App;
