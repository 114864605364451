import { useContext, useState } from 'react';
import '../css/Signup.css';
import Vector from '../assets/Vector.png';
import { PiEyeLight, PiEyeSlash } from "react-icons/pi";
import { useNavigate } from 'react-router-dom';
import ReactPasswordChecklist from "react-password-checklist";
import NavbarContext from './NavbarContext';
import axios from 'axios';
import swal from 'sweetalert';
import CryptoJS from 'crypto-js';

const constants = require('../config/constants.js');

export default function Signup() {
  const { setIsLoginPage } = useContext(NavbarContext);
  let navigate = useNavigate();

  const loginClick = () => {
    navigate('/');
    setIsLoginPage(true);
  }

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  

  const [criteria, setCriteria] = useState({ minLength: true, specialChar: true, number: true, capital: true, });

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
    setPasswordType(passwordVisible ? "password" : "text");
  };

  const emailPattern = /^(([^<>()[\]\\.,;:@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\s]{2,}))$/;
  const passwordPattern=/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])/;
  const [email_error, setEmailError] = useState("");
  const [password_error, setPasswordError] = useState("");
  const [showPasswordChecklist, setShowPasswordChecklist] = useState(false);

  const [signupError, setSignupError] = useState("");
  const url = `${constants.BACKEND_SERVER_BASEURL}/users/registerUser`;

  const validateEmail = () => {
    
    if (email.trim() === "") {
      setEmailError("Enter Email Address");
      return false;
    } else if (!emailPattern.test(email)) {
      setEmailError("Enter Valid Email Address");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };
  
  const validatePassword = () => {
    if (password.trim() === "") {
      setPasswordError("Enter Password");
      return false;
    } else if (password.trim().length < 8) {
      setPasswordError("Password should be at least 8 characters");
      return false;
    } else if (!passwordPattern.test(password)) {
      setPasswordError("Password must contain one digit, lowercase, uppercase, and one special character");
      return false;
    } else {
      setPasswordError("");
      return true;
    }
  };

  const handleFocus = () => {
    setShowPasswordChecklist(true);
  };

  const handleBlur = () => {
    setShowPasswordChecklist(false);
  };
  
  const handleSignup = async (e) => {
    e.preventDefault();
  
    var isValidEmail            = validateEmail();
    var isValidPassword         = validatePassword();

    if (isValidEmail && isValidPassword) {
      try {
         // Encrypt email and password before sending to API
         const encryptedEmail = CryptoJS.AES.encrypt(email, constants.SECRET_KEY).toString();
         const encryptedPassword = CryptoJS.AES.encrypt(password, constants.SECRET_KEY).toString();
        const response = await axios.post(url, { email:encryptedEmail, password:encryptedPassword });
        const data = response.data;
        if (data.error_code === 0) {
          // localStorage.setItem('userId',data.user_id)
          swal({
            title: "Account created successfully!",
            text: "Please log in with your email and password.",
            icon: "success",
            button: "OK",
          }).then(() => {
            // Redirect to the login page
            // window.location.href = "/"; 
            navigate('/');
          });
          localStorage.setItem('accessToken', data.access_token)
          // navigate('/');
        } else if (data.error_code === 403) {
          setSignupError("User Already Exist");
        }
      } catch (error) {
        console.log(error);
      }
    }
  
    
  };

  const handlePasswordChange = (event) => { const newPassword = event.target.value; setPassword(newPassword); updateCriteria(newPassword); };

const updateCriteria = (password) => { setCriteria({ minLength: password.length <5 ? false : true, specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password), number: /\d/.test(password), capital: /[A-Z]/.test(password), }); };
const unmetCriteria = Object.keys(criteria).filter((key) => !criteria[key]); 
const allCriteriaMet = unmetCriteria.length === 0;

  return (
    <div className='container-fluid'>
      <div className='bg_container row'>
        <div className='left_container col-12 col-md-6  '>

            <p className='signup_heading '>
              Your Guide to <span className='signup_heading_highlight'>Responsible Investing and Beyond</span>
            </p>
            <p className='signup_paragraph'>
              By using ESGPilot, businesses and organisations can reduce the effort and resources required for ESG reporting, enabling them to focus more on their sustainability initiatives and less on the intricacies of report generation.
            </p>

        </div>
        <div className='right_container col-12 col-md-6 col-sm-8'>
          <div className='form_container'>
            <form className='signup-form' onSubmit={handleSignup}>
              <div className='input_box_logo '>
                <img src={Vector} alt='vector' className='vector_image' />
                <p className='esgpilot_heading'>ESG<span className='pilot'>PILOT</span></p>
              </div>
              {signupError && (<p style={{ color: 'red', textAlign: "center" }}>{signupError}</p>)}
              <div>
                <input
                  type='email'
                  placeholder='Email Address'
                  id='email'
                  name='email'
                  value={email}
                  onChange={(e) => { setEmail(e.target.value) }}                  
                  className='input_box'
                />                
              </div>
              {email_error && (<p style={{ color: 'red' }}>{email_error}</p>)}

              <div className='password_img_container '>
                <input
                  type={passwordType}
                  placeholder='Set Password'
                  name='password'
                  id='password'
                  value={password}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={(e) => { handlePasswordChange(e) }}
                  className='input_box hide-password'
                />
                <div>{passwordVisible ? <PiEyeLight className='eye_image' onClick={togglePasswordVisibility} /> : <PiEyeSlash className='eye_image' onClick={togglePasswordVisibility} />}</div>
                {showPasswordChecklist && (
  !allCriteriaMet && (<div className="password-checklist-card">
    <ReactPasswordChecklist
    // rules={["minLength", "specialChar", "number", "capital"]}
    rules={unmetCriteria}
    minLength={5}
    value={password}
    onChange={(isValid) => {}}
    // iconSize={8}
    invalidTextColor="#FF0033"
    validTextColor="#4BCA81"
    className="custom-checklist-styles"
  />
  </div>
)
)}
              </div>
              {password_error && (<p style={{ color: 'red', width:"370px"}}>{password_error}</p>)}

              <button type='submit' className='signup_button' >Sign Up</button>

              <p className='signup_text'>Already have an account? <span onClick={loginClick} className='login_link_sgu'>Login</span></p>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
