import React, { useState, useEffect } from "react";
import "../../css/PopUpCardsCSS/PreviewOfTheProcess.css";
import arrowIcon from "../../assets/eva_arrow-back-outline.svg";
import previewImage from "../../assets/Preview.svg";
import axios from "axios";
import constants from "../../config/constants";
import { convertLength } from "@mui/material/styles/cssUtils";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-bootstrap";
import { toast } from "react-toastify";

export default function PreviewOfTheProcess({ showModal, onExit ,onBack,selectedIds,onAction}) {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const Navigate=useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
 
  if (!showModal) return null;

  const popupApiCalls = async () => {
    const form_data1 = localStorage.getItem("LetsGetStartedform");
    const form_data2 = localStorage.getItem("CheckBoxData");
    const token = localStorage.getItem("accessToken");
  
    let checkboxValues = Object.values(JSON.parse(form_data2));
  
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
  
      await axios.post(`${constants.BACKEND_SERVER_BASEURL}/users/addUserDetails`, form_data1, { headers }).then(
        async response1 =>{
          const company_id = response1.data.company_id;
          const accessToken = response1.data.access_token;
          localStorage.setItem("accessToken", accessToken);
      
          const newHeaders = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          };
      
          let checks = JSON.stringify(checkboxValues);
          const response2 = await axios.post(`${constants.BACKEND_SERVER_BASEURL}/users/addFramework`, { frameworks: checks }, { headers: newHeaders });
          // Remove items from local storage after successful API calls
    
        if(response1.status === 200 && response2.status === 200){
          return true;
        }else {
          toast.warning("Something went wrong", {toastId: 22});
          
        }
       }
      )
    } catch (error) {
      console.error("Error making API request:", error);
      return false;
    }
  };
  


  

  const handleNextButtonClick = () => {
    // Reload the page
   const result= popupApiCalls();
   if(result){
    localStorage.removeItem("LetsGetStartedform");
    localStorage.removeItem("CheckBoxData");
   setTimeout(() => {
    window.location.reload();
   }, 100);
    onExit();
   }    
  };



  

  return (
    <div>
      <div className="overlay">
        <div className="container lgs-modal">
          <div className="col-12 preview_container">
            {isMobileView && (
              <img
                onClick={onBack}
                src={arrowIcon}
                className="arrow_image mb-3"
                alt="arrowImage"
              />
            )}
            <div className="row d-flex justify-content-center">
              <p className="preview_heading">Preview of the process</p>
              <p className="preview_paragraph">
                Upload all the documents you have we will help you create your
                report
              </p>
              <div className="d-flex justify-content-center">
                <img src={previewImage} alt="Image" className="preview_image" />
              </div>
            </div>
            <div className="frameworks_button d-flex justify-content-end mt-5">
            <button
                    type='button'
                    className='preview_back_button framework_btn'
                    onClick={onBack}
                  >
                    Back
                  </button>
              <button
                type="submit"
                className="preview_next_button preview_back_button"
                onClick={handleNextButtonClick}
                // onClick={()=>{handleNextButtonClick();onAction()}}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
