import { React, useState,useEffect } from 'react';
import '../css/Login.css';
import '../css/Signup.css';
import Vector from '../assets/Vector.png';
import { PiEyeLight, PiEyeSlash } from "react-icons/pi";
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import NavbarContext from './NavbarContext';
import axios from 'axios';
import CryptoJS from 'crypto-js';
const constants = require('../config/constants.js');

export default function Login() {
  const { setIsLoginPage } = useContext(NavbarContext);
  let navigate = useNavigate();

  const signupClick = () => {
    navigate('/Signup');
    setIsLoginPage(false);
  };

  const ForgotPasswordClick = () => {
    navigate('/ForgotPassword');
    setIsLoginPage(false);
  };

  // email validation
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [email_error, setEmailError] = useState("");
  const [password_error, setPasswordError] = useState("");
  
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const storedCredentials = localStorage.getItem('credentials');
    if (storedCredentials) {
      try {
      const decryptedCredentials = decryptCredentials(storedCredentials);
      setEmail(decryptedCredentials.email);
      setPassword(decryptedCredentials.password);
      setRememberMe(true);
    }catch (error) {
      console.error('Failed to decrypt stored credentials:', error);
    }
  }
  }, []);

  const emailPattern = /^(([^<>()[\]\\.,;:@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\s]{2,}))$/;

  const validateEmail = () => {
    if (email.trim() === "") {
      setEmailError("Enter Email Address");
      return false;
    } else if (!emailPattern.test(email)) {
      setEmailError("Enter Valid Email Address");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };

  const validatePassword = () => {
    if (password.trim() === "") {
      setPasswordError("Enter Password");
      return false;
    } else {
      setPasswordError("");
      return true;
    }
  };

  // password eye image functionality
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
    setPasswordType(passwordVisible ? "password" : "text");
  };

  // Encrypt credentials before storing in local storage
  const encryptCredentials = (credentials) => {
    return CryptoJS.AES.encrypt(JSON.stringify(credentials), constants.SECRET_KEY).toString();
  };

  // Decrypt stored credentials
  const decryptCredentials = (encryptedCredentials) => {
    const bytes = CryptoJS.AES.decrypt(encryptedCredentials, constants.SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  // login API integration
  const url = `${constants.BACKEND_SERVER_BASEURL}/users/loginUser`;
  const [loginError, setLoginError] = useState("");
  const credentials = {
    email: encryptCredentials(email),
    password: encryptCredentials(password)
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    var isValidEmail = validateEmail();
    var isValidPassword = validatePassword();

    if (isValidEmail && isValidPassword) {
      try {
        const response = await axios.post(url, credentials);
        const data = response.data;
        if (data.error_code === 0) {
          if (rememberMe) {
            // Store credentials if "Remember Me" is checked
            localStorage.setItem('credentials', encryptCredentials({ email, password }));
          } else {
            // Clear stored credentials if "Remember Me" is unchecked
            localStorage.removeItem('credentials');
          }
          localStorage.setItem("accessToken",data.access_token)
          if(localStorage.getItem("thru_email")=="true"){
            navigate(`/SummaryReport/${localStorage.getItem("iterationId")}/${localStorage.getItem("companyId")}`);
          }
          else{
            navigate('/home');
          }
        } else if (data.error_code === 401) {
          setLoginError(data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className='container-fluid px-0'>
      <div className='bg_container_image'>
      <div className='row px-0 mx-0'>
     
        <div className='left_container_login col-12 col-md-6'>
          <div>
            <p className='login_heading'>Reduce  <span className='login_heading_highlight'>90% </span> of your <br/>time and effort</p>
            <p className='login_paragraph'>By using ESGPilot, businesses and organizations can reduce the effort and resources required for ESG reporting, enabling them to focus more on their sustainability initiatives and less on the intricacies of report generation.
            <br></br>
            <br></br>
              With Smart ESG AI, you can rapidly extract the relevant ESG mandatory fields, identify gaps in your ESG reporting, and generate stunning reports.
            </p>
          </div>
        </div>
        <div className='right_container_login col-12 col-md-6'>
          <div className='form_container_login'>
            <form className='login-form' onSubmit={handleLogin}>
              <div className='input_box_logo'>
                <img src={Vector} alt='vector' className='vector_image img-fluid' />
                <p className='esgpilot_heading'>ESG<span className='pilot'>PILOT</span></p>
              </div>
              {loginError && (<p style={{ color: 'red', textAlign: "center" }}>{loginError}</p>)}
              <div>
                <input
                  type='email'
                  placeholder='Email Address'
                  id='email'
                  value={email}
                  onChange={(e) => { setEmail(e.target.value) }}
                  className='input_box'
                />
              </div>
              {email_error && (<p style={{ color: 'red' }}>{email_error}</p>)}

              <div className='password_img_container'>
                <input
                  type={passwordType}
                  placeholder='Password'
                  id='password'
                  value={password}
                  onChange={(e) => { setPassword(e.target.value) }}
                  className='input_box'
                />
                <div>{passwordVisible ? <PiEyeLight className='eye_image' onClick={togglePasswordVisibility} /> : <PiEyeSlash className='eye_image' onClick={togglePasswordVisibility} />}</div>
              </div>
              {password_error && (<p style={{ color: 'red' }}>{password_error}</p>)}

              <div className='checkbox_container'>
                <div>
                <label className='remember_me_text'>
                  <input type="checkbox"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    className='checkbox_style'
                    id='remember_checkbox'
                  />
                  Remember me
                </label>
                </div>
                <div>
                  <span onClick={ForgotPasswordClick} className='forget_password_text'>Forgot your password?</span>
                </div>
              </div>
              <button type='submit' className='login_button' >Login</button>
              <p className='signup_text'>Don't have an account? <span onClick={signupClick} className='login_link_sgu'>Sign Up</span></p>
            </form>
          </div>
        </div>
        </div>

      </div>
    </div>
  );
}
