import React, { useState, useEffect } from 'react';
import axios from '../config/axiosConfig';
import HeaderComponent from '../components/HeaderComponent';
import { useNavigate } from 'react-router-dom';
import Loader from '../config/Loader';
import moment from 'moment-timezone';
import '../css/Iterations.css'
import files from '../assets/fileImage.svg'
// import Table from 'react-bootstrap/Table';
const constants = require("../config/constants.js");

export default function Iterations() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [iterations, setIterations] = useState([]);
  
  const accessToken = localStorage.getItem('accessToken')
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  // const [activeTab, setActiveTab] = useState(
  //   localStorage.getItem("activeTab" || "home")
  // );

  // const accessToken = localStorage.getItem("accessToken");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };

  useEffect(() => {
    axios
      .get(`${constants.BACKEND_SERVER_BASEURL}/iterations/getAllIterations`, {
        headers,
      })
      .then((response) => {
        setIterations(response.data);
        setIsDataLoaded(true)
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching iterations:", error);
        setLoading(false);
      });
  }, []);

  const viewIteration = (iterationId, companyId) => {
    navigate(`/SummaryReport/${iterationId}/${companyId}`);
  };

  const navigateToHome  = () => {
    // setActiveTab("home");
    // localStorage.setItem("activeTab", "home");
    navigate("/home");
  };

  return (
    <div className="iterations-container">
      <HeaderComponent />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "50px 0px 50px 0px",
        }}
      >
        {loading ? (
          <Loader />
        ) : ( isDataLoaded && iterations.length === 0 ? (
          <div className="d-flex flex-column align-items-center justify-content-center iterations_card">
            <img src={files} alt="files" className="files_image" />
            <p className="empty-iterations-message">
              No reports available!
            </p>
            <p className="upload_doc_text">
              You need to upload documents, to view reports here.
            </p>
            <button onClick={navigateToHome } className="get_started_btn">
              Let’s Get Started
            </button>
          </div>
        ) : (
          <table
            className="table table-hover"
            style={{ maxWidth: "75%", borderRadius: "10px" }}
          >
            <thead>
              <tr className="table-active table_heading_style">
                <th>Iteration</th>
                {/* <th>Name</th> */}
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {iterations.map((iteration) => (
                <tr className="table_body_style" key={iteration.iteration_id}>
                  <td className={`align-middle table_width`}>
                    {moment
                      .utc(iteration.inserted_date_time)
                      .local()
                      .format("DD MMM YYYY hh:mm A")}
                  </td>
                  {/* <td className={`align-middle`}>{iteration.iteration_name}</td> */}
                  <td className={`align-middle table_width_status`}>
                    {
                      constants.ITERATION_STATUS_IDS[
                        iteration.iteration_status_id
                      ]
                    }
                  </td>
                  <td className={`align-middle table_width_action`}>
                    {iteration.iteration_status_id === 3 && (
                      <button
                        className={`btn btn-outline-success table_body_style`}
                        onClick={viewIteration.bind(
                          null,
                          iteration.iteration_id,
                          iteration.company_id
                        )}
                      >
                        VIEW
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )
        )}
      </div>
    </div>
  );
}
