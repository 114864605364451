import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from '../config/Loader';
import '../css/DataCollection.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'react-accessible-accordion/dist/fancy-example.css';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, AccordionItemState } from 'react-accessible-accordion'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import RadialSeparators from './RadialSeparators.js';
import 'react-circular-progressbar/dist/styles.css';
import { TiTick } from "react-icons/ti";
import NavbarContext from './NavbarContext.js';
const constants = require('../config/constants.js');


const MobileDataCollection = ({ groupedCollectionData, selectedJuri }) => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState([]);
  let [subCategory, setSubCategory] = useState('');
  const [inputValues, setInputValues] = useState({});
  const textareaRefs = useRef([]);
  const [loading, setLoading] = useState(false);
  const { iterationId, companyId } = useParams();

  const [eDataPointsSummary, setEDataPointsSummary] = useState(0);
  const [sDataPointsSummary, setSDataPointsSummary] = useState(0);
  const [gDataPointsSummary, setGDataPointsSummary] = useState(0);
  const [eDataSummary, setEDataSummary] = useState([]);
  const [sDataSummary, setSDataSummary] = useState([]);
  const [gDataSummary, setGDataSummary] = useState([]);
  let [collectionData, setCollectionData] = useState({ groupedCollectionData })
  const [subcategoryInputs, setSubcategoryInputs] = useState({});

  const navigate = useNavigate();
  const { setCategoryName,setCategory,category } = useContext(NavbarContext);

  useEffect(() => {
    getDataCollection()
    setCollectionData({ ...groupedCollectionData });
  }, [groupedCollectionData, selectedJuri])


  const handleCategorySelect = (category, subcategories) => {
    reloadData()
    setCategory(category)
    const firstSubcategoryName = Object.keys(subcategories)[0];
    setSubCategory(firstSubcategoryName)
    const firstSubcategory = subcategories[firstSubcategoryName];
    handleSubcategorySelect(firstSubcategory, firstSubcategoryName)
    setSelectedCategory(category[0]);

  };
  const handleSubcategorySelect = (fields, subcategory) => {
    setSelectedSubcategory(fields);
    setSubCategory(subcategory);
    // Load saved input values for the selected subcategory
    const initialValues = subcategoryInputs[subcategory] || {};
    fields.forEach((item) => {
      initialValues[item.data_point_id] = initialValues[item.data_point_id] || item.data_point_value || '';
    });
    setInputValues(initialValues);
  };



  const reloadData = async () => {
    let groupedData = {};
    var juriLevelDataPoints = await axios.get(`${constants.BACKEND_SERVER_BASEURL}/dataPoints/getAllDataPointsByIterationIdAndCompanyIdAndJuriId/${iterationId}/${companyId}/${selectedJuri}`);
    var responseData = juriLevelDataPoints.data;
    responseData.forEach(item => {
      if (!groupedData[item.category]) {
        groupedData[item.category] = {};
      }
      if (!groupedData[item.category][item.bucket]) {
        groupedData[item.category][item.bucket] = [];
      }
      const exists = groupedData[item.category][item.bucket].some(existingItem => existingItem.data_point_id === item.data_point_id);
      if (!exists) {
        groupedData[item.category][item.bucket].push(item);
      }
    });
    setCollectionData({ ...groupedData })
  }
  const getDataCollection = async () => {
    setLoading(true);
    axios.get(`${constants.BACKEND_SERVER_BASEURL}/dataPoints/getSummaryReport/${iterationId}/${companyId}/${selectedJuri}`)
      .then((response) => {
        setEDataSummary(response.data.eDataPointsSummary)
        setSDataSummary(response.data.sDataPointsSummary)
        setGDataSummary(response.data.gDataPointsSummary)
        let e_data = response.data.eDataPointsSummary
        let s_data = response.data.sDataPointsSummary
        let g_data = response.data.gDataPointsSummary
        e_data = e_data.slice(1);
        s_data = s_data.slice(1);
        g_data = g_data.slice(1);

        let e_index = e_data.find((item, index) => {
          if (item.empty_count != 0) {
            if (groupedCollectionData && groupedCollectionData.Environment) {
              const subcategory = groupedCollectionData.Environment;
              if (subcategory && subcategory[item.bucket]) {
                let subcategoryData = subcategory[item.bucket];
                setSubCategory(item.bucket)
                handleCategorySelect([constants.category_environment], subcategory)
                handleSubcategorySelect(subcategoryData, item.bucket)
              }
            }

            return item
          }
        });
        let s_index;
        let g_index;
        if (e_index == undefined) {
          s_index = s_data.find((item, index) => {
            if (item.empty_count != 0) {
              if (groupedCollectionData && groupedCollectionData.Social) {
                const subcategory = groupedCollectionData.Social;
                if (subcategory && subcategory[item.bucket]) {
                  let subcategoryData = subcategory[item.bucket];

                  setSubCategory(item.bucket)
                  setSelectedCategory(constants.category_social);
                  handleCategorySelect([constants.category_social], subcategory)
                  handleSubcategorySelect(subcategoryData, item.bucket)
                }
              }
              return item
            }
          });
        } else if (e_index == undefined && s_index == undefined) {
          g_index = g_data.find((item, index) => {
            if (item.empty_count != 0) {
              if (groupedCollectionData && groupedCollectionData.Governance) {
                const subcategory = groupedCollectionData.Governance;
                if (subcategory && subcategory[item.bucket]) {
                  let subcategoryData = subcategory[item.bucket];
                  setSubCategory(item.bucket)
                  handleCategorySelect([constants.category_governance], subcategory)
                  handleSubcategorySelect(subcategoryData, item.bucket)
                }
              }
              return item
            }
          });
        } else if (e_index == undefined && s_index == undefined && g_index == undefined) {
          if (groupedCollectionData && groupedCollectionData.Environment) {
            setSelectedCategory(constants.category_environment);
            const climateChangeSubcategory = groupedCollectionData.Environment;
            if (climateChangeSubcategory && climateChangeSubcategory[constants.subcategory_climatechange]) {
              let climateData = climateChangeSubcategory[constants.subcategory_climatechange];
              setSubCategory([constants.subcategory_climatechange])
              setSelectedSubcategory(climateData);
              handleSubcategorySelect(climateData, constants.subcategory_climatechange)
              handleCategorySelect([constants.category_environment], climateChangeSubcategory)
            }
          }
        }

        response.data.eDataPointsSummary.map(item => {

          if (item.bucket == constants.bucket_total) {
            setEDataPointsSummary(findPercentage(item.non_empty_count, item.empty_count));
          }

        })
        response.data.sDataPointsSummary.map(item => {
          if (item.bucket == constants.bucket_total)
            setSDataPointsSummary(findPercentage(item.non_empty_count, item.empty_count));

        })
        response.data.gDataPointsSummary.map(item => {
          if (item.bucket == constants.bucket_total)
            setGDataPointsSummary(findPercentage(item.non_empty_count, item.empty_count));

        })
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error('error:', error);
      });

  }



  useEffect(() => {
    // Adjust textarea heights on initial render and whenever inputValues change
    textareaRefs.current.forEach((textarea) => {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    });
  }, [inputValues]);

  function findPercentage(completedDataPoints, incompleteDataPoints) {
    var percent = (completedDataPoints / (completedDataPoints + incompleteDataPoints)) * 100;
    return Math.round(percent);
  }
  const handleSubcategorySelectWrapper = (fields, subcategory) => {
    handleSubcategorySelect(fields, subcategory);
    setCategoryName(subcategory)
  };


  const handleNavigate = (fields_data, subcategory) => {

    const initialValues = subcategoryInputs[subcategory] || {};
    fields_data.forEach((item) => {
      initialValues[item.data_point_id] = initialValues[item.data_point_id] || item.data_point_value || '';
    });
    navigate(`/MobileReports/${iterationId}/${companyId}`, { state: { fields_data, initialValues } });
  }



  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid main-container">
          <div className="row px-2 bg-white" >
            <div className="col-12 col-md-6 col-lg-3 mb-3">
              <Accordion className='custom-accordion' preExpanded={[selectedCategory]}>
                {Object.entries(collectionData).map(([category, subcategories]) => (
                  <AccordionItem className='accordion-item' key={category} uuid={category}>
                    <AccordionItemHeading onClick={() => handleCategorySelect([category], subcategories)}>
                      <AccordionItemButton className={`accordion__button ${selectedCategory === category ? 'active' : ''}`}>
                        <div className='d-flex justify-content-between align-items-center'>
                          {category}
                          <div style={{ width: 30, height: 30 }} className='progress-bar'>
                            <CircularProgressbarWithChildren className='custom-circular-bar'
                              value={category === constants.category_environment ? eDataPointsSummary : category === constants.category_social ? sDataPointsSummary : gDataPointsSummary}
                              text={`${category === constants.category_environment ? eDataPointsSummary : category === constants.category_social ? sDataPointsSummary : gDataPointsSummary}%`}
                              strokeWidth={10}
                              styles={buildStyles({
                                strokeLinecap: "butt",
                                pathColor: '#00A656',
                                trailColor: '#E5F6EE',
                                textColor: '#000'
                              })}
                            >
                              <RadialSeparators
                                count={12}
                                style={{
                                  background: "#fff",
                                  width: "1px",
                                  height: `${20}%`
                                }}
                              />
                            </CircularProgressbarWithChildren>
                          </div>
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ul className="list-group">
                        {Object.entries(subcategories).map(([subcategory, fields], index) => (
                          <li key={`${subcategory}-${fields.id}`} className={`sub-category-items ${subCategory === subcategory ? '' : ''}`}>
                            <div className='row'>
                              <p
                                className="sub-categories mb-0 col-10"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  handleSubcategorySelectWrapper([...fields], subcategory);
                                  handleNavigate([...fields], subcategory)
                                }}
                              >
                                {subcategory}
                              </p>
                              <div className='col-2'>
                                {category === constants.category_environment ? (eDataSummary[index + 1]?.bucket === subcategory ? (
                                  eDataSummary[index + 1]?.empty_count === 0 ? <TiTick /> : ''
                                ) : '') : ''}
                                {category === constants.category_social ? (sDataSummary[index + 1]?.bucket === subcategory ? (
                                  sDataSummary[index + 1]?.empty_count === 0 ? <TiTick /> : ''
                                ) : '') : ''}
                                {category === constants.category_governance ? (gDataSummary[index + 1]?.bucket === subcategory ? (
                                  gDataSummary[index + 1]?.empty_count === 0 ? <TiTick /> : ''
                                ) : '') : ''}
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>



          </div>
        </div>
      )}
    </div>

  );
};

export default MobileDataCollection;
