import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavbarContext from './NavbarContext';
import constants from '../config/constants';
import '../css/Signup.css';
import CryptoJS from 'crypto-js';
import { ToastContainer, toast } from "react-toastify";
import { FaArrowLeft } from "react-icons/fa6";
import backArrow from '../assets/backArrow.svg'
import "react-toastify/dist/ReactToastify.css";

export default function ForgotPassword() {
  const { setIsLoginPage } = useContext(NavbarContext);
  const navigate = useNavigate();

  const loginClick = () => {
    navigate('/');
    setIsLoginPage(true);
  }

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [emailSent, setEmailSent] = useState(false); // State to track if email was sent successfully

  const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(""); 
  };
  const validateEmail = () => {
    if (email.trim() === "") {
      setEmailError("Enter Email Address");
      return false;
    } else if (!emailPattern.test(email)) {
      setEmailError("Enter Valid Email Address");
      return false;
    } else {
      setEmailError(null); // Clear the error message when email is valid
      return true;
    }
  };
  

  const encryptEmail = (email) => {
    return CryptoJS.AES.encrypt(email, constants.SECRET_KEY).toString();
  };

  const handleSignup = (e) => {
    e.preventDefault();
    setButtonDisabled(true);

    const isValidEmail = validateEmail();

    if (isValidEmail) {
      const encryptedEmail = encryptEmail(email);

      const url = `${constants.BACKEND_SERVER_BASEURL}/users/forgetPassword`;
      axios.post(url, { email:encryptedEmail })
        .then(response => {
          const data = response.data;
          if (data.message === "Email sent successfully!") {
            setEmailSent(true);
          } 
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data.message === "Please enter a valid registered email") {
              toast.error("Please enter your email associated with your account");
            } else {
              toast.error("Something went wrong! Please try again.");
            }
          } else {
            toast.error("Network Error. Please try again.");
          }
          setEmailSent(false);
        })
        .finally(() => {
          setButtonDisabled(false);
        });
    }
  };

  return (
    <div className='container-fluid'>
      <div className='bg_container row'>
        <div className='left_container col-12 col-md-6'>
          <div>
            <p className='login_heading_fpw'>Reduce <span className='login_heading_highlight_fpw'>90% </span> of your <br/>time and effort</p>
            <p className='login_paragraph_fpw'>By using ESGPilot, businesses and organizations can reduce the effort and resources required for ESG reporting, enabling them to focus more on their sustainability initiatives and less on the intricacies of report generation.
              <br></br>
              <br></br>
              With Smart ESG AI, you can rapidly extract the relevant ESG mandatory fields, identify gaps in your ESG reporting, and generate stunning reports.
            </p>
          </div>
        </div>
        <div className='right_container col-12 col-md-6'>
          {emailSent ? (
            <div className='form_container_email_sent d-flex justify-content-center align-items-center'>
              <div>
                <p className='Forget_password email_sent'>Email Sent</p>
                <p className='forget_password_content email_sent'>We have sent the password reset link to your registered email.</p>
                <button className="back_to_login" onClick={loginClick}><FaArrowLeft className='back_arrow'/>Back To Login</button>
              </div>
            </div>
          ) : (
            <div className='form_container_fpw d-flex justify-content-center align-items-center'>
              <form className='signup-form' onSubmit={handleSignup}>
                <div>
                  <p className='Forget_password'>{emailSent ? "Email Sent" : "Forgot Password"}</p>
                  <p className='forget_password_content'>Enter your email associated with your account</p>
                </div>
                <div>
                  <input
                    type='email'
                    placeholder='Email Address'
                    id='email'
                    name='email'
                    value={email}
                    onChange={handleEmailChange}
                    className='input_box'
                  />
                </div>
                {emailError && (<p style={{ color: 'red' }}>{emailError}</p>)}
                {!emailSent && (
                  <>
                    <button type='submit' className='signup_button'>Submit</button>
                    <p className='signup_text'><span onClick={loginClick} className='login_link_sgu'><img src={backArrow} alt='arrow' />Back To Login</span></p>
                  </>
                )}
              </form>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}


