import { Navigate, useNavigate, Outlet} from "react-router-dom";
import { useEffect } from "react";
import Navbar from "./Navbar";
import { jwtDecode } from "jwt-decode";
import { IdleTimerProvider, useIdleTimer } from 'react-idle-timer';
import CryptoJS from 'crypto-js';
const constants = require('../config/constants.js');



const ProtectedRoute = () => {
  const navigate = useNavigate()
  
  const prevRoute = window.location.pathname;

  const pathParts = prevRoute.split('/');

  const { reset } = useIdleTimer({
    timeout: 1000 * 60 * 60 , // 1 hour
    onIdle: () => {
      console.log('User is idle!');
      navigate('/')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('encryted_email');
      localStorage.removeItem('iterationId');
      localStorage.removeItem('companyId');
      localStorage.removeItem('thru_email');
    },
    // onActive: () => {
    //   console.log('User is active!'); 
    // },
  });

  // const handleUserActivity = () => {
  //   reset(); // Reset the idle timer on user activity
  // };

    useEffect(() => {
        const intervalId = setInterval(() => {
          // Reload the page
          window.location.reload();
        }, 60*60*1000);
    
        if (pathParts[1] === 'SummaryReport') {
          localStorage.setItem('iterationId', pathParts[2]);
          localStorage.setItem('companyId', pathParts[3]);
        
          if (typeof pathParts[4] !== 'undefined') {
            localStorage.setItem('thru_email', true);
            try {
              const bytes = CryptoJS.AES.decrypt(pathParts[4], constants.CIPHER_KEY);
              const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
              if (decryptedData.trim() === '') {
                console.error('Decrypted data is empty.');
              } else {
                console.log('Decrypted data:', decryptedData);
              }
            } catch (err) {
              console.error('Error decrypting data:', err);
            }
          }
        }            
        // Clean up the interval when the component is unmounted
        return () => clearInterval(intervalId);
      }, [pathParts]);

      const token = localStorage.getItem('accessToken')
      const thru_email = localStorage.getItem('thru_email')
      const iterationId = localStorage.getItem('iterationId')
      const companyId = localStorage.getItem('companyId')

      let isExpired;

      if (token !== null){
        
        const decoded = jwtDecode(token)
        const currentTime = Math.floor(Date.now() / 1000);
        const expirationTime = decoded.exp 
        isExpired = expirationTime < currentTime
      }

    return(
        (token !== null ) && !isExpired ? 
          <><IdleTimerProvider><Navbar /><Outlet /></IdleTimerProvider></> 
        : (
          thru_email == 'true' ? <Navigate to={`/SummaryReport/${iterationId}/${companyId}`}/> : <Navigate to='/' />
        )
    )
}

export default ProtectedRoute