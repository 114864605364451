import React, { useState,useEffect } from "react";
import "../../css/PopUpCardsCSS/FrameWorkInterested.css";
import environment from "../../assets/frameworkIntersted.png";
import { FRAMEWORK_IDS } from "../../config/constants";
import axios from "axios";
import arrowIcon from '../../assets/eva_arrow-back-outline.svg'
const constants = require("../../config/constants.js");

export default function FrameWorkInterested({
  showModal,
  onAction,
  onBack,
  companyId,
}) {
  const [checkboxIdMap,setCheckboxIdMap] =useState( {
    csrdCheckbox: FRAMEWORK_IDS.CSRD_CHECKBOX,
    brsrCheckbox: FRAMEWORK_IDS.BRSR_CHECKBOX,
    griCheckbox: FRAMEWORK_IDS.GRI_CHECKBOX,
    cdpCheckbox: FRAMEWORK_IDS.CDP_CHECKBOX,
  });


  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [checkboxError, setCheckboxError] = useState("");
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

useEffect (()=>{
    let frame_work_data = localStorage.getItem("CheckBoxData")
    if(frame_work_data){
    setCheckboxIdMap(JSON.parse(frame_work_data))
    }
  },[])
  const handleCheckboxChange = (event, id) => {
    if (id === "allCheckbox") {
      // If 'All' checkbox is selected, select all other checkboxes
      const allCheckboxes = Object.keys(checkboxIdMap);
      if (event.target.checked == false) {
        setSelectedCheckboxes([]);
      } else {
        setSelectedCheckboxes(allCheckboxes);
      }
    } else {
      // If any other checkbox is selected, toggle its selection
      const newSelectedCheckboxes = selectedCheckboxes.includes(id)
        ? selectedCheckboxes.filter((checkboxId) => checkboxId !== id)
        : [...selectedCheckboxes, id];
      setSelectedCheckboxes(newSelectedCheckboxes);
    }
  };

  const isAllCheckboxSelected =
    selectedCheckboxes.length === Object.keys(checkboxIdMap).length;


  const handleNext = async () => {
    const selectedIds = selectedCheckboxes.map(
      (checkbox) => checkboxIdMap[checkbox]
    );

    if (selectedIds.length === 0) {
      setCheckboxError("Please Select At Least One Framework");
      return false;
    } else {
      setCheckboxError("");
      localStorage.setItem("CheckBoxData",JSON.stringify(checkboxIdMap))
      onAction(selectedIds);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div>
      {showModal && (
        <div className="overlay">
          <div className="container lgs-modal framework_container">
            <div className="frameworks_card_container">
              <div className="container_gap col-12">
              {isMobileView && <img
                  src={arrowIcon}
                  className="arrow_image mb-4 mt-4"
                  alt="arrowImage"
                  onClick={onBack}
                />}
                <p className="framework_heading">Frameworks Interested</p>
                <p className="framework_paragraph">
                  We auto selected the mandatory ones as per your location
                  details provided
                </p>
              </div>
              <div className="framework_image text-center">
                <img
                  src={environment}
                  className="environment_images mx-auto"
                  alt="environmentImage"
                />
              </div>
              <div className="checkbox_card">
              <div className="d-flex justify-content-center ">
                <div className=" checkboxes_container d-flex justify-content-center align-items-center flex-wrap ">
                  <div className="frameworks_checkbox">
                    <label
                      htmlFor="csrdCheckbox"
                      className="framework_checkbox_label"
                    >
                      <input
                        type="checkbox"
                        id="csrdCheckbox"
                        className="framework_check_box"
                        name="framework"
                        value="CSRD"
                        checked={selectedCheckboxes.includes("csrdCheckbox")}
                        onChange={(event) =>
                          handleCheckboxChange(event, "csrdCheckbox")
                        }
                      />
                      CSRD
                    </label>
                  </div>
                  <div className="frameworks_checkbox">
                    <label
                      htmlFor="brsrCheckbox"
                      className="framework_checkbox_label"
                    >
                      <input
                        type="checkbox"
                        id="brsrCheckbox"
                        className="framework_check_box"
                        name="framework"
                        value="BRSR"
                        checked={selectedCheckboxes.includes("brsrCheckbox")}
                        onChange={(event) =>
                          handleCheckboxChange(event, "brsrCheckbox")
                        }
                      />
                      BRSR
                    </label>
                  </div>
                  <div className="frameworks_checkbox">
                    <label
                      htmlFor="griCheckbox"
                      className="framework_checkbox_label"
                    >
                      <input
                        type="checkbox"
                        id="griCheckbox"
                        className="framework_check_box"
                        name="griCheckbox"
                        value="GRI"
                        checked={selectedCheckboxes.includes("griCheckbox")}
                        onChange={(event) =>
                          handleCheckboxChange(event, "griCheckbox")
                        }
                      />
                      GRI
                    </label>
                  </div>
                  <div className="frameworks_checkbox">
                    <label
                      htmlFor="cdpCheckbox"
                      className="framework_checkbox_label"
                    >
                      <input
                        type="checkbox"
                        id="cdpCheckbox"
                        className="framework_check_box"
                        name="cdpCheckbox"
                        value="CDP"
                        checked={selectedCheckboxes.includes("cdpCheckbox")}
                        onChange={(event) =>
                          handleCheckboxChange(event, "cdpCheckbox")
                        }
                      />
                      CDP
                    </label>
                  </div>
                  <div className="frameworks_checkbox">
                    <label
                      htmlFor="allCheckbox"
                      className="framework_checkbox_label"
                    >
                      <input
                        type="checkbox"
                        id="allCheckbox"
                        className="framework_check_box"
                        name="allCheckbox"
                        value="ALL"
                        checked={isAllCheckboxSelected}
                        onChange={(event) =>
                          handleCheckboxChange(event, "allCheckbox")
                        }
                      />
                      All
                    </label>
                  </div>
                  <div className="frameworks_checkbox"></div>
                </div>
              </div>
              </div>
              {checkboxError && <p style={{ color: "red" }}>{checkboxError}</p>}
              <div className="framework-nav-button mt-5">
                <button
                    type='button'
                    className='framework_back_button framework_btn'
                    onClick={onBack}
                  >
                    Back
                  </button>
                <button
                  type="button"
                  className="framework_back_button framework_next_button"
                  onClick={handleNext}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
