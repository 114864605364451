import React, { useState, useEffect } from "react";
import submissionImg from "../../assets/submissionCompleted.png";
import { FaPlus } from "react-icons/fa6";
import "../../css/PopUpCardsCSS/SubmissionCompleted.css";

export default function SubmissionCompleted({ onClose, onCancel }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <div className="overlay">
        <div className="container submission-model">
          <div>
            {isMobile ? (
              <p></p>
            ) : (
              <p
                className="close d-flex justify-content-end"
                onClick={() => onCancel()}
              >
                &times;
              </p>
            )}
            <div className=" submission_card_container ">
              <img
                src={submissionImg}
                alt="submissionImg"
                className="submission_note_img"
              />
              <p className="submission_heading d-block text-center">Submission Completed!</p>
              {isMobile ? (
                <p className="submission_paragraph">
                  Our AI will analyze and generate reports from your documents.
                  You'll get an email when it's ready for your review.
                </p>
              ) : (
                <>
                  <p className="submission_paragraph">
                    Our advanced AI will analyse your documents and generate the
                    reports. Take a break while we handle everything for you.
                  </p>
                  <p className="submission_paragraph">
                    Expect an email notification when the upload is done for
                    review. Enjoy your downtime!
                  </p>
                </>
              )}
            </div>
            <div className=" d-flex justify-content-end xxxx">
              <button className="close_button" onClick={() => onClose()}>
                {isMobile ? (
                  <>
                    <FaPlus /> Upload Documents
                  </>
                ) : (
                  "Close"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
