// import earthESG from '../assets/earthESG.gif'; 
import earthESG from '../assets/earthESGLoader.gif';
import '../css/Loader.css';

const Loader = () => {
  
  return (
    <div>
        <div className="loading-spinner-overlay">
          <div className="loading-spinner">
            <img src={earthESG} alt="Loading" />
          </div>
        </div>
    </div>
  );
};

export default Loader;