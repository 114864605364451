/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

let constants = {
    // BACKEND_SERVER_BASEURL: 'http://localhost:3000',
    // BACKEND_SERVER_BASEURL: 'https://server.esgpilot.ai/app',
    BACKEND_SERVER_BASEURL: window.location.hostname === "localhost" ? "http://localhost:3000" : window.location.protocol + "//" + window.location.hostname + "/app",
    ESG_AI_BASE_URL: 'https://server.esgpilot.ai/esg',
    ITERATION_STATUS: {INSERTING_DATA: 0, DATA_INSERTED_SUCCESSFULLY: 1, PROCESSING_ITERATION: 2, DONE: 3},
    ITERATION_STATUS_IDS: ['NO DATA', 'PENDING', 'PROCESS STARTED', 'DONE'],
    FRAMEWORK_IDS: {CSRD_CHECKBOX: 1, BRSR_CHECKBOX: 2, GRI_CHECKBOX: 3, CDP_CHECKBOX: 4},
    CIPHER_KEY: 'GbDILJMEli1fSVF1sccY8cxNivkfDBzct3EG5Y4sg_0=',
    EMAIL_ACCESS_TOKEN : '123ABCabc456!@#def0987TewuwmnvmzxvWTQT)*^e2$#1b.d><XMCa.xSKDSIFSCNs',
    SECRET_KEY: 'secret',
    category_social: 'Social',
    category_environment: 'Environment',
    category_governance:'Governance',
    bucket_total:'TOTAL',
    subcategory_climatechange:'Climate Change'
}
module.exports = Object.freeze(constants);