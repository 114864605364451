import React, { useEffect, useState } from "react";
import "../../css/PopUpCardsCSS/LetsGetStarted.css";
import countriesData from "../../countries.json";
import Select from 'react-select';
const constants = require("../../config/constants.js");

export default function LetsGetStarted({ showModal, onAction, setCompanyId }) {
  //form data
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    role: "",
    company_name: "",
    hq_country: "India",
  });

  useEffect (()=>{
    let form_data = localStorage.getItem("LetsGetStartedform")
    if(form_data){
    setFormData(JSON.parse(form_data))
    }
  },[])

  const [errors, setErrors] = useState({});

  const validateInputFields = () => {
    const newErrors = {};
    if (formData.first_name.trim() === "")
      newErrors.first_name = "First name is required";
    if (formData.last_name.trim() === "")
      newErrors.last_name = "Last name is required";
    if (formData.role.trim() === "") newErrors.role = "Role is required";
    if (formData.company_name.trim() === "")
      newErrors.company_name = "Company name is required";
    if (formData.hq_country.trim() === "")
      newErrors.hq_country = "HQ Country is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" })); // clear error for the field
  }

  const handleSelectChange = (selectedOption) => {
    setFormData((prev) => ({ ...prev, hq_country: selectedOption.value }));
    setErrors((prev) => ({ ...prev, hq_country: "" })); // clear error for the field
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    localStorage.setItem("LetsGetStartedform",JSON.stringify(formData))
    var validateInput = validateInputFields();
    if (validateInput) {
      onAction()
    }
  };

  const countryOptions = countriesData.map((eachCountry) => ({
    value: eachCountry.name,
    label: eachCountry.name,
  }));

  const defaultOption = countryOptions.find(
    (option) => option.value === formData.hq_country
  );

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      padding:'0px'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#777E90',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#777E90',
    }),
  };

  if (!showModal) return null;
  return (
    <div>
      <div className="overlay">
        <div className="container lgs-modal">
          <div className="lets_get_started_container">
            <div className="container_gap col-12">
              <p className="form_heading">Lets get you started</p>
              <p className="form_paragraph">
                This helps us determine the frameworks you have to fill in
              </p>
            </div>

            <form className="form_card">
              <div className=" input_field_container d-flex flex-wrap justify-content-between align-items-center">
                <div className="input_fields">
                  {/* <label htmlFor='firstName' className='form_label'>First Name</label> */}
                  <label htmlFor="firstName" className="form_label">
                    First Name <span className="required-asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    className="form_input_box"
                    id="firstName"
                    name='first_name'
                    autocomplete="off"
                    value={formData.first_name}
                    onChange={handleChange}
                  />
                  {errors.first_name && (
                    <span className="error-message">{errors.first_name}</span>
                  )}
                </div>
                <div className="input_fields">
                  {/* <label htmlFor='lastName' className='form_label'>Last Name</label> */}
                  <label htmlFor="lastName" className="form_label">
                    Last Name <span className="required-asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    className="form_input_box"
                    id="lastName"
                    name='last_name'
                    autocomplete="off"
                    value={formData.last_name}
                    onChange={handleChange}
                  />
                  {errors.last_name && (
                    <span className="error-message">{errors.last_name}</span>
                  )}
                </div>
                <div className="input_fields">
                  {/* <label htmlFor='companyName' className='form_label'>Company Name</label> */}
                  <label htmlFor="companyName" className="form_label">
                    Company Name <span className="required-asterisk">*</span>
                  </label>
                  <input type='text'
                  className='form_input_box' 
                  id="companyName"
                  name='company_name'
                  autocomplete="off"
                  value={formData.company_name}
                  onChange={handleChange}
                  />
                  {errors.company_name && (
                    <span className="error-message">{errors.company_name}</span>
                  )}
                </div>
                {/* <div className="input_fields">
                  <label htmlFor="country" className="form_label">
                    HQ Country{" "}
                    <span className="HQ_country">(Company Head Quarter's)</span>{" "}
                    <span className="required-asterisk">*</span>
                  </label>
                  <select
                    className="form_input_box"
                    id="country"
                    required
                    name="hq_country"
                    value={formData.hq_country}
                    onChange={handleChange}
                  >
                    <option value="India" defaultChecked>
                      India
                    </option>
                    {countriesData.map((eachCountry) => (
                      <option key={eachCountry.id} value={eachCountry.name}>
                        {eachCountry.name}
                      </option>
                    ))}
                  </select>
                  {errors.hq_country && (
                    <span className="error-message">{errors.hq_country}</span>
                  )}
                </div> */}

                <div className="input_fields">
                  <label htmlFor="country" className="form_label">
                    HQ Country{" "}
                    <span className="HQ_country">(Company Head Quarter's)</span>{" "}
                    <span className="required-asterisk">*</span>
                  </label>
                  <Select
                    className="form_select_box"
                    id="country"
                    required
                    name="hq_country"
                    value={defaultOption}
                    onChange={handleSelectChange}
                    options={countryOptions}
                    styles={customStyles}
                  />
                  {errors.hq_country && (
                    <span className="error-message">{errors.hq_country}</span>
                  )}
                </div>
                <div className="input_fields">
                  {/* <label htmlFor='role' className='form_label'>Role In Company</label> */}
                  <label htmlFor="role" className="form_label">
                    Role In Company <span className="required-asterisk">*</span>
                  </label>
                  <input type='text' 
                  className='form_input_box' 
                  id="role"
                  name='role'
                  autocomplete="off"
                  value={formData.role}
                  onChange={handleChange}
                  />
                  {errors.role && (
                    <span className="error-message">{errors.role}</span>
                  )}
                </div>
              </div>
            </form>
            <div className="d-flex align-self-end lgs_button_container">
              <button
                type="submit"
                className="lets_get_you_started_button"
                onClick={handleSubmit}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
