import '../css/home.css';
import React, { useState, useEffect } from 'react';
import HeaderComponent from '../components/HeaderComponent';
import DataCollection from '../components/DataCollection';
import MobileDataCollection from '../components/MobileDataCollection.js';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../config/axiosConfig';
import Form from 'react-bootstrap/Form';
import { FaPlus } from "react-icons/fa6";
import UploadDocumentsPopup from '../components/PopUpCards/UploadDocumentsPopup.js';
import Loader from '../config/Loader';

const constants = require('../config/constants.js');

const Report = () =>  {
  const navigate      = useNavigate();
  var groupedData     = {};
  
  const { iterationId, companyId }                  = useParams();
  const [groupedCollectionData, setgroupedData]     = useState([]);
  const [dropDownOptions, setDropDownOptions]       = useState([]);
  const [iterationName, setiterationName]           = useState('');
  const [selectedJuri, setSelectedJuri]             = useState(1);
  const [uploadPopup, setUploadPopup]               = useState(false)
  const [loading, setLoading]                       = useState(false);
  const [isMobile, setIsMobile]                     = useState(window.innerWidth <= 767);


  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);



  useEffect(() => { 
    setLoading(true); 
    axios.get(`${constants.BACKEND_SERVER_BASEURL}/dataPoints/getJurisdictionsByCompanyIdAndIterationId/${iterationId}/${companyId}`)
          .then((response) => {
            const options = response.data;
            setDropDownOptions(options);
            if (options.length > 0) {
              axios.get(`${constants.BACKEND_SERVER_BASEURL}/iterations/getIterationDetails/${iterationId}`)
          .then((response) => {
            setiterationName(response.data[0].iteration_name)
          })
          .catch((error) => {
            console.error('error:', error);
          });
              getDataCollection(options[0].juri_id);
            }
          })
          .catch((error) => {
            console.error('error:', error);
          });
    
  }, [iterationId, companyId]);
  

  const getDataCollection = async (juriId) => {
    groupedData = {};
    var juriLevelDataPoints =  await axios.get(`${constants.BACKEND_SERVER_BASEURL}/dataPoints/getAllDataPointsByIterationIdAndCompanyIdAndJuriId/${iterationId}/${companyId}/${juriId}`);
    var responseData = juriLevelDataPoints.data;

    responseData.forEach(item => {
      setLoading(false); // Clear loading state after navigation

      if (!groupedData[item.category]) {
        groupedData[item.category] = {};
      }
      if (!groupedData[item.category][item.bucket]) {
        groupedData[item.category][item.bucket] = [];
      }
      const exists = groupedData[item.category][item.bucket].some(existingItem => existingItem.data_point_id === item.data_point_id);
      if (!exists) {
        groupedData[item.category][item.bucket].push(item);
      }
    });
    setgroupedData(groupedData);
    setSelectedJuri(juriId)

  }

  const handleSummaryReportButton = () => {
     // Set loading state to true when button is clicked
      navigate(`/SummaryReport/${iterationId}/${companyId}`);
    
  };
  

  const handleUploadFile=() =>{
    setUploadPopup(true)
  }
  const closeUploadFile =()=>{
    setUploadPopup(false)
  }

  return (
    <div className='home-container'>
       {loading ? <Loader />:<>
       <HeaderComponent/>
        <div className='container-fluid'>
          <div className='row px-2'>
            {/* <div className="col-12">
              <h3 className="text-center">{iterationName}</h3>
            </div> */}
            
              <div className='col-6 col-md-6 col-lg-3 select-framework-container'>
                <Form.Select onChange={(event) => getDataCollection(event.target.value)} className='select-framework'>
                  {dropDownOptions.map(option => <option key={option.juri_id} value={option.juri_id} >{option.juri_name}</option>)}
                </Form.Select>
              </div>
              <div className='col-md-6 col-lg-9 mt-3 d-flex justify-content-end align-items-center'>
                
              {/* <button className={`edit-page-button d-flex align-items-center justify-content-center `} onClick={handleUploadFile}> <FaPlus className='plus_icon'/>Upload Documents</button> */}
              {!isMobile && (
                <button className={`edit-page-button generate-button generate_reports_mobile_view`} onClick={handleSummaryReportButton} disabled={loading}>Generate Reports</button>
              )}
              </div>
            
          </div>
          
        </div>
        <div></div>
        {isMobile 
          ? <MobileDataCollection groupedCollectionData={groupedCollectionData} selectedJuri={selectedJuri} />
          : <DataCollection groupedCollectionData={groupedCollectionData} selectedJuri={selectedJuri} />
          }
          <UploadDocumentsPopup uploadPopup={uploadPopup} closeUploadFile={closeUploadFile}/>
        </>
       }
       
      </div>
  );
}

export default Report;