import '../css/home.css';
import HeaderComponent from '../components/HeaderComponent';
import FileUpload from '../components/FileUpload';
export default function Home() {
  return (
        <div className='home-container'>
          <HeaderComponent/>
          <div>
            <FileUpload/>
          </div>
     
        </div>
  );
}