import React, { useContext, useEffect, useState } from 'react';
import '../css/Signup.css';
import Vector from '../assets/Vector.png';
import { PiEyeLight, PiEyeSlash } from "react-icons/pi";
import { useNavigate, useParams } from 'react-router-dom';
import ReactPasswordChecklist from "react-password-checklist";
import NavbarContext from './NavbarContext';
import { jwtDecode } from "jwt-decode";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaArrowLeft } from "react-icons/fa6";
import CryptoJS from 'crypto-js';
const constants = require('../config/constants.js');

export default function ResetPassword() {
  const { setIsLoginPage } = useContext(NavbarContext);
  let navigate = useNavigate();

  const loginClick = () => {
    navigate('/');
    setIsLoginPage(true);
  }

  const ForgotPasswordClick = () => {
    navigate('/ForgotPassword');
    setIsLoginPage(false);
  };

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // State variables and function for password visibility toggle
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
    setPasswordType(passwordVisible ? "password" : "text");
  };

  // State variables and function for confirm password visibility toggle
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
    setConfirmPasswordType(confirmPasswordVisible ? "password" : "text");
  };

  const { access_token } = useParams()

  const passwordPattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])/;

  const [showPasswordChecklist, setShowPasswordChecklist] = useState(false);
  const [email, setEmail] = useState("");

  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const url = `${constants.BACKEND_SERVER_BASEURL}/users/resetPassword`;

  // State variable for controlling form submission and success message
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showTokenExpiredMessage, setShowTokenExpiredMessage] = useState(false); // New state variable

  async function validateAndExtract(token, secretKey) {
    if (token) {
      const decoded = jwtDecode(token, secretKey);
      const currentTime = Math.floor(Date.now() / 1000);
      const expirationTime = decoded.exp;
      return { valid: expirationTime > currentTime, email: decoded.data }
    }
    return { valid: false, email: null }
  }

  useEffect(() => {
    async function checkTokenValidity() {
      const { valid, email } = await validateAndExtract(access_token, constants.EMAIL_ACCESS_TOKEN);
      if (!valid) {
        setShowTokenExpiredMessage(true); 
        setEmail(email); 
      } else {
        setEmail(email);
      }
    }
    checkTokenValidity();
  }, [access_token]);

  const handleFocus = () => {
    setShowPasswordChecklist(true);
  };

  const handleBlur = () => {
    setShowPasswordChecklist(false);
  };

  const encryptData = (data) =>{
    return CryptoJS.AES.encrypt(data,constants.SECRET_KEY).toString();
  }

  const handleSignup = async (e) => {
    e.preventDefault();
  
    let passwordError = "";
    let confirmPasswordError = "";
  
    // Validate password
    if (password.trim() === "") {
      passwordError = "Password is required";
    } else if (password.trim().length < 8) {
      passwordError = "Password should be at least 8 characters";
    } else if (!passwordPattern.test(password)) {
      passwordError = "Password must contain at least one digit, one lowercase, one uppercase, and one special character";
    }
  
    // Validate confirm password
    if (confirmPassword.trim() === "") {
      confirmPasswordError = "Password is required";
    } else if (confirmPassword !== password) {
      confirmPasswordError = "Passwords do not match";
    }
  
    // Update state with errors
    setPasswordError(passwordError);
    setConfirmPasswordError(confirmPasswordError);
  
    // If no errors, proceed with submission
    if (!passwordError && !confirmPasswordError) {
      try {
        const encryptedPassword = encryptData(password);
        const encryptedEmail = encryptData(email);
        const response = await axios.post(url, { email:encryptedEmail, password:encryptedPassword });
        const data = response.data;
        if (data.message === "Password updated successfully") {
          setShowSuccessMessage(true); // Show success message
        }
        setFormSubmitted(true); 
      } catch (error) {
        if (error.response.data.message === "Approval Pending. Thank you for your patience") {
          toast.error("Approval Pending. Thank you for your patience");
        } else {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.dismiss(); 
    }
  };
  
  return (
    <div className='container-fluid'>
      <div className='bg_container row'>
        <div className='left_container col-12 col-md-6'>
          <div>
            <p className='login_heading_fpw'>Reduce <span className='login_heading_highlight_fpw'>90% </span> of your <br/>time and effort</p>
            <p className='login_paragraph_fpw'>By using ESGPilot, businesses and organizations can reduce the effort and resources required for ESG reporting, enabling them to focus more on their sustainability initiatives and less on the intricacies of report generation.
              <br></br>
              <br></br>
              With Smart ESG AI, you can rapidly extract the relevant ESG mandatory fields, identify gaps in your ESG reporting, and generate stunning reports.
            </p>
          </div>
        </div>
        {showTokenExpiredMessage ? (
          <div className='right_container col-12 col-md-6'>
            <div className='form_container_email_sent d-flex justify-content-center align-items-center'>
              <div>
                <p className='Forget_password email_sent'>Link Expired!</p>
                <p className='forget_password_content email_sent'>Your link has expired. Please request a new password reset link.</p>
                <button className="signup_button" onClick={ForgotPasswordClick}>Back To Forgot Password</button>
              </div>
            </div>
          </div>
        ) : (
          <div className='right_container col-12 col-md-6'>
            {formSubmitted && showSuccessMessage ? (
              <div className='form_container_pw_success d-flex justify-content-center align-items-center'>
                <div>
                  <p className='Forget_password email_sent'>Password Updated Successfully!</p>
                  <p className='forget_password_content email_sent'>Your password has been updated successfully.</p>
                  <button className="back_to_login" onClick={loginClick}><FaArrowLeft className='back_arrow'/>Back To Login</button>
                </div>
              </div>
            ) : (
              <div className='form_container_reset_pw'>
                <form className='signup-form' onSubmit={handleSignup}>
                  <div className=''>
                  <p className='new_password'>Create New Password</p>
                  </div>

                  <div className='password_img_container'>
                    <input
                      type={passwordType}
                      placeholder='New Password'
                      name='password'
                      id='password'
                      value={password}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      onChange={(e) => { setPassword(e.target.value); setPasswordError(""); }}
                      className='input_box hide-password'
                    />
                    <div>
                      {/* Toggle visibility icon */}
                      {passwordVisible ? <PiEyeLight className='eye_image' onClick={togglePasswordVisibility} /> : <PiEyeSlash className='eye_image' onClick={togglePasswordVisibility} />}
                    </div>
                    {showPasswordChecklist && (
                      <div className="password-checklist-card">
                        <ReactPasswordChecklist
                          rules={["minLength", "specialChar", "number", "capital"]}
                          minLength={8}
                          value={password}
                          onChange={(isValid) => { }}
                          iconSize={13}
                          invalidTextColor="#FF0033"
                          validTextColor="#4BCA81"
                          className="custom-checklist-styles"
                        />
                      </div>
                    )}
                  </div>
                  {passwordError && (<p style={{ color: 'red', textAlign: "left" }}>{passwordError}</p>)}

                  <div className='password_img_container'>
                    <input
                      type={confirmPasswordType}
                      placeholder='Confirm Password'
                      name='confirmPassword'
                      id='confirmPassword'
                      value={confirmPassword}
                      onChange={(e) => { setConfirmPassword(e.target.value); setConfirmPasswordError(""); }}
                      className='input_box hide-password'
                    />
                    <div>
                      {confirmPasswordVisible ? <PiEyeLight className='eye_image' onClick={toggleConfirmPasswordVisibility} /> : <PiEyeSlash className='eye_image' onClick={toggleConfirmPasswordVisibility} />}
                    </div>
                  </div>
                  {confirmPasswordError && (<p style={{ color: 'red', textAlign: "left" }}>{confirmPasswordError}</p>)}

                  <button type='submit' className='signup_button'>Submit</button>

                </form>
              </div>
            )}
          </div>
        )}
        <ToastContainer />
      </div>
    </div>
  )
}

